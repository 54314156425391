import { render } from "./ReviewEvent.vue?vue&type=template&id=0cda52a2&scoped=true"
import script from "./ReviewEvent.vue?vue&type=script&lang=js"
export * from "./ReviewEvent.vue?vue&type=script&lang=js"

import "./ReviewEvent.vue?vue&type=style&index=0&id=0cda52a2&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-0cda52a2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0cda52a2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0cda52a2', script)) {
    api.reload('0cda52a2', script)
  }
  
  module.hot.accept("./ReviewEvent.vue?vue&type=template&id=0cda52a2&scoped=true", () => {
    api.rerender('0cda52a2', render)
  })

}

script.__file = "src/page/promotion/ReviewEvent.vue"

export default script