<template>
  <div class="review-event">
    <AppNav>리뷰 쓰고 스벅 커피 받자!</AppNav>
    <section class="review-event__main-img--1"></section>
    <button class="review-event__review-button" @click="openReviewForm">게시물 링크 남기기</button>
    <section class="review-event__main-img--2"></section>
  </div>
</template>

<script>
import { tracker, native } from '@/tools';

import AppNav from '../../components/AppNav.vue';

export default {
  name: 'ReviewEvent',
  components: {
    AppNav,
  },
  data() {
    return {};
  },
  mounted() {},

  methods: {
    openReviewForm() {
      tracker.recordUserAction('review_button');
      native.openWebView('https://tutoring24.typeform.com/to/Fn2pi5WL', '리뷰 작성');
    },
  },
};
</script>

<style scoped>
.review-event {
  background: #f8f8f8;
}

.review-event__main-img--1 {
  margin-top: 42px;
  width: 100vw;
  height: calc(100vw * 6.61);
  background: #f29ca9 url(//res.tutoring.co.kr/res/images/evt/202110/review1/review_evt_oct1.jpg) no-repeat 50%;
  background-size: contain;
}
.review-event__review-button {
  --width: calc(100vw - 164px);
  display: block;
  margin: 60px auto;
  width: var(--width);
  height: calc(0.32 * var(--width));
  background: #6c508e;
  border-radius: 50px;
  color: #ffffff;
  font-size: calc(0.083 * var(--width));
  font-weight: bold;
}
.review-event__main-img--2 {
  width: 100vw;
  height: calc(100vw * 1.47);
  background: url(//res.tutoring.co.kr/res/images/evt/202110/review1/review_evt_oct2.jpg) no-repeat 50%;
  background-size: contain;
}
</style>
